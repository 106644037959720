import React from "react";
import { Link, useLocation } from "react-router-dom";
import axios from "axios";
import {
  BrowserView,
  MobileView,
  isBrowser,
  isAndroid,
} from "react-device-detect";
import _, { debounce } from "lodash";
import Players from "../../../assets/images/S__86941730.jpg";
import Icon from "../../../assets/images/logo.png";
import Nav from './components/common/Nav';

const ForgetPasswordPage = (props) => {
  const { search } = useLocation();
  const [state, setState] = React.useState({
    email: "",
  });
  const [browserShow, setBrowserShow] = React.useState(true);

  const handleRequestToken = (e) => {
    e.preventDefault();
    //let message = document.getElementById("message");
    let tokenBtn = document.getElementById("button");

    tokenBtn.disabled = true;
    tokenBtn.style.backgroundColor = "grey";

    axios
      .post("/api/reset_password", {
        email: state.email,
      })
      .then((res) => {
        if (!res.data.successful) {
          let message = document.getElementById("message");
          message.innerText = res.data.message;
          setTimeout(() => (message.innerText = ""), 5000);
        }else{
          let message = document.getElementById("message2");
          message.innerText = "成功寄送密碼重置E-mail，請至註冊信箱收信。";
          setTimeout(() => (message.innerText = ""), 5000);
        }
        tokenBtn.innerText = "重新寄送驗證碼";
        tokenBtn.disabled = false;
        tokenBtn.style.backgroundColor = "#46b8c9";
      })
      .catch((err) => {
        let message = document.getElementById("message");
        message.innerText = "發生錯誤，請稍後再試";
        setTimeout(() => (message.innerText = ""), 5000);
        tokenBtn.innerText = "重新寄送驗證碼";
        tokenBtn.disabled = false;
        tokenBtn.style.backgroundColor = "black";
      });
  };

  const handleChange = (e) => {
    setState({ ...state, [e.target.name]: e.target.value });
  };

  return (
    <div style={{
      height: "100%"}}>
      <Nav/>
      <div
        className="w3-container w3-light-grey"
        style={{
          height: "100%",
          padding: "100px 16px",
          fontFamily: "noto sans tc,Arial,sans-serif",
          fontWeight:"600"
        }}
        id="contact"
      >
        <p className="w3-center w3-large"></p>
        <div style={{ marginTop: "48px" }}>
          <div className="w3-col l12">
            <div className="w3-col l4">&nbsp;</div>
            <div className="w3-col l4">
              <h3
                className="w3-center"
                style={{ fontWeight: "700", fontSize: "24px" }}
              >
                重置密碼申請
              </h3>
              <p style={{ margin: "20px 3vw" }}>
                <input
                  style={{ borderRadius: "10px" }}
                  className="w3-input w3-border m-radius"
                  type="email"
                  placeholder="請輸入帳號（Email）"
                  required
                  name="email"
                  value={state.email}
                  onChange={handleChange}
                />
              </p>
              <p
                id="message"
                style={{ color: "#DB485B", minHeight: "0px",padding:"0 3vw" }}
              ></p>
                            <p
                id="message2"
                style={{ color: "#288EEC", minHeight: "0px",padding:"0 3vw" }}
              ></p>
              <p style={{ margin: "20px 3vw" }}>
                <button
                  className="w3-button w3-black"
                  type="submit"
                  style={{ width: "100%" }}
                  id="button"
                  onClick={(e) => handleRequestToken(e)}
                >
                  <i className="fa-solid fa-angle-right"></i> 發送驗證信
                </button>
              </p>

              <p className="w3-center" style={{ fontSize: "15px" }}>
                <a className="w3-center-a" href="/reset_password">
                  {" "}
                  輸入驗證碼
                </a>
              </p>
              <h4
                className="w3-center"
                style={{
                  marginTop: "100px",
                  fontSize: "20px",
                  fontWeight: "700",
                }}
              >
                <a className="w3-center-a" href="/">
                  {" "}
                  ← 回到登入頁面
                </a>
              </h4>
            </div>
            <div className="w3-col l4"></div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default ForgetPasswordPage;
