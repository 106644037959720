import React from "react";
import { Link, useLocation } from "react-router-dom";

import Icon2 from "../../../../../assets/images/logo2.png";
import { Icon } from "@iconify/react";
import _, { debounce } from "lodash";

const Nav = (props) => {
  const { search } = useLocation();
  const { smallDevice } = props;
  var mySidebar = document.getElementById("mySidebar");

  function w3_open() {
    if (mySidebar.style.display === "block") {
      mySidebar.style.display = "none";
    } else {
      mySidebar.style.display = "block";
    }
  }

  // Close the sidebar with the close button
  function w3_close() {
    mySidebar.style.display = "none";
  }

  return (
    <div>
      <div
        className="w3-top"
        style={{
          fontFamily: "noto sans tc,Arial,sans-serif",
          fontWeight: "600",
        }}
      >
        <div
          className="w3-bar w3-black w3-card"
          id="myNavbar"
          style={{
            alignItems: "center",
            minHeight: "60px",
            fontSize: "15px",
          }}
        >
          <a
            href="https://build.playreal.city/"
            className="w3-bar-item  w3-wide w3-hide-small"
            style={{ width: "200px" }}
          >
            <img style={{ width: "100%" }} src={Icon2} />
          </a>
          <a
            href="javascript:void(0)"
            className=" w3-bar-item w3-button w3-left w3-hide-large w3-hide-medium"
            onClick={() => w3_open()}
            style={{
              padding: "20px",
              opacity: smallDevice == false ? "0" : "1",
            }}
          >
            <Icon icon="fa:bars" color="white" width="24" height="24" />
          </a>
          <a
            href="https://build.playreal.city/"
            className="w3-bar-item w3-button w3-hide-large w3-hide-medium w3-display-middle"
            style={{ padding: "0" }}
          >
            <img style={{ height: "60px" }} src={Icon2} />
          </a>
        </div>
      </div>
    </div>
  );
};

export default Nav;
