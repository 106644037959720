import React from "react";
import { createRoot } from "react-dom/client";
import {
  BrowserRouter as Router,
  Switch,
  Route,
  Redirect,
  useParams,
} from "react-router-dom";
import axios from "axios";

import LoginPage from "./pages/LoginPage";
import RegistrationPage from "./pages/RegistrationPage";
import ForgetPasswordPage from "./pages/ForgetPasswordPage";
import ResetPasswordPage from "./pages/ResetPasswordPage";
import Nav from "./pages/components/common/Nav";
import { changeLanguage } from "./i18n";

const rootElement = document.getElementById("root");
const root = createRoot(rootElement);

const Activation = () => {
  const queryString = window.location.search;
  const urlParams = new URLSearchParams(queryString);
  const token_local = urlParams.get("confirmationHash");
  const email_local = urlParams.get("email");
  const type = urlParams.get("type") == "joinGroup" ? 2 : 1;
  const user_email = document.getElementById("root").dataset.userEmail;
  const [errorMessage, setErrorMessage] = React.useState("");
  const [showRedirectButton, setShowRedirectButton] = React.useState(false);
  const [showResendButton, setShowResendButton] = React.useState(false);
  const [canResend, setCanResend] = React.useState(true);
  const [countdown, setCountdown] = React.useState(60);

  console.log(type);
  console.log(token_local);
  console.log(email_local);
  console.log(user_email);
  console.log(window.location.href);
  console.log(window.location.search);
  console.log(window.location.pathname);

  React.useEffect(() => {
    if (errorMessage) {
      setTimeout(() => {
        setErrorMessage("");
      }, 3000);
    }
  }, [errorMessage]);

  React.useEffect(() => {
    let timer;
    if (!canResend) {
      timer = setInterval(() => {
        setCountdown((prev) => {
          if (prev <= 1) {
            clearInterval(timer);
            setCanResend(true);
            return 60;
          }
          return prev - 1;
        });
      }, 1000);
    }
    return () => clearInterval(timer);
  }, [canResend]);

  React.useEffect(() => {
    if (!user_email && !email_local) {
      console.log("redirect to login");
      window.location.href = "/login";
    }
    setTimeout(function () {
      if (token_local != null && email_local != null) {
        document.getElementById("token").value = token_local;
        autoSubmit();
      }
    }, 1000);
  }, []);

  const handleSubmit = (e) => {
    e.preventDefault();
    let token = document.getElementById("token").value;
    let email = user_email || email_local;
    if (type == 1) {
      axios
        .post(`/api/users/account/confirmMail`, {
          email: email,
          token: token,
        })
        .then((res) => {
          if (res.data["successful"] !== false) {
            window.location.href = "/";
          } else {
            if (res.data.state === 104) {
              setErrorMessage("驗證碼有誤，請重新輸入。");
            } else if (res.data.state === 105) {
              if (user_email) {
                setShowRedirectButton(false);
                setShowResendButton(true);
                setErrorMessage("驗證碼已過期，請重新申請驗證信。");
              } else {
                setShowRedirectButton(true);
                setShowResendButton(false);
                setErrorMessage("驗證碼已過期，請重新登入後再申請驗證信。");
              }
            }
          }
        })
        .catch((err) => {
          console.log(err);
        });
    } else {
      axios
        .post(`/api/users/account/joinMail`, {
          email: email,
          token: token,
        })
        .then((res) => {
          if (res.data["successful"] !== false) {
            alert("加入完成，將重新自動登入！");
            window.location.href = "/";
          } else {
            alert(res.data.message);
          }
        })
        .catch((err) => {
          console.log(err);
          if (err.response?.status === 401) {
            window.location.href = "/login";
          }
        });
    }
  };
  const redirectToLogin = () => {
    window.location.replace("/");
  };
  const autoSubmit = () => {
    let email = user_email || email_local;
    let token = token_local;
    if (type == 1) {
      axios
        .post(`/api/users/account/confirmMail`, {
          email: email,
          token: token,
        })
        .then((res) => {
          if (res.data["successful"] !== false) {
            //reload
            window.location.href = "/";
          }
        })
        .catch((err) => {
          console.log(err);
        });
    } else {
      axios
        .post(`/api/users/account/joinMail`, {
          email: email,
          token: token,
        })
        .then((res) => {
          if (res.data["successful"] !== false) {
            alert("加入完成，將重新自動登入！");
            window.location.href = "/";
          }
        })
        .catch((err) => {
          console.log(err);
        });
    }
  };

  const sendConfirmMail = () => {
    if (!user_email || !canResend) return;
    axios
      .post("/api/account_confirm", {
        email: user_email,
      })
      .then((res) => {
        if (res.data["successful"] !== false) {
          alert("已成功發送，請至信箱確認");
          setCanResend(false);
        }
      })
      .catch((err) => {
        console.log(err);
      });
  };

  return (
    <div style={{ height: "100%", overflow: "hidden" }}>
      <Nav />
      <div
        className="w3-container w3-light-grey"
        style={{
          padding: "100px 16px",
          margin: "0 -24px",
          fontWeight: "600",
          height: "100%",
        }}
        id="contact"
      >
        <p className="w3-center w3-large"></p>
        <div style={{ marginTop: "24px" }}>
          <div className="w3-col l12">
            <div className="w3-col l4">&nbsp;</div>
            <div className="w3-col l4">
              <h3 className="w3-center" style={{ fontWeight: "700" }}>
                {type == 1 ? "帳號驗證中" : "加入Playreal的新組織"}
              </h3>
              <p style={{ margin: "20px 3vw" }}>
                電子郵件信箱：{user_email || email_local}
                <br />
                <br />
                <input
                  className="w3-input w3-border m-radius"
                  type="text"
                  placeholder="輸入驗證碼"
                  required
                  name="token"
                  id="token"
                />
                <br />
                <p
                  style={{
                    display: errorMessage ? "block" : "none",
                    width: "100%",
                    color: "red",
                  }}
                >
                  {errorMessage}
                </p>
                <button
                  className="w3-button w3-black"
                  type="submit"
                  style={{ width: "100%" }}
                  onClick={handleSubmit}
                >
                  送出
                </button>
                {user_email && showResendButton && (
                  <div>
                    <button
                      className="w3-button w3-white"
                      type="button"
                      style={{ width: "100%", marginTop: "10px" }}
                      onClick={sendConfirmMail}
                      disabled={!canResend}
                    >
                      重新發送驗證信
                    </button>
                    {!canResend && (
                      <p style={{ textAlign: "center", color: "gray" }}>
                        {countdown} 秒後可再次發送
                      </p>
                    )}
                  </div>
                )}
                {type == 2 ? (
                  <div
                    style={{
                      fontSize: "1em",
                      fontWeight: "500",
                      marginTop: "5vh",
                      color: "#a5a5a5",
                    }}
                  >
                    如有任何問題，請
                    <span
                      style={{
                        fontSize: "1em",
                        fontWeight: "700",
                        marginTop: "5vh",
                        color: "#616161",
                      }}
                    >
                      與我們聯絡。
                    </span>
                  </div>
                ) : (
                  <button
                    className="w3-button w3-white"
                    type="submit"
                    style={{
                      display: showRedirectButton ? "block" : "none",
                      width: "100%",
                    }}
                    onClick={redirectToLogin}
                  >
                    重新登入
                  </button>
                )}
              </p>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

const Join = () => {
  const queryString = window.location.search;
  const urlParams = new URLSearchParams(queryString);
  const token_local = urlParams.get("confirmationHash");
  const email_local = urlParams.get("email");
  console.log("succed in join");
  console.log(token_local);
  console.log(email_local);
  const handleSubmit = (e) => {
    e.preventDefault();
    axios
      .post(`/api/users/account/joinMail`, {
        email: email_local,
        token: token_local,
      })
      .then((res) => {
        if (res.data["successful"] !== false) {
          alert("加入完成，將重新自動登入！");
          window.location.href = "/";
        } else {
          alert(res.data.message);
        }
      })
      .catch((err) => {
        console.log(err);
        if (err.response?.status === 401) {
          window.location.href = "/login";
        }
      });
  };

  return (
    <div
      style={{
        display: "flex",
        flexDirection: "column",
        height: "100vh",
        justifyContent: "center",
        alignItems: "center",
      }}
    >
      <form>
        <div style={{ display: "flex" }}>
          <input
            className="form-control"
            id="token"
            style={{ marginRight: "15px", display: "none" }}
          />
          <input
            type="submit"
            className="btn btn_Orange"
            style={{ width: "150px", textAlign: "center" }}
            value="加入Playreal的新組織"
            onClick={handleSubmit}
          />
        </div>
      </form>
      <div
        style={{
          fontSize: "1em",
          fontWeight: "500",
          marginTop: "5vh",
          color: "#a5a5a5",
        }}
      >
        如有任何問題，請
        <span
          style={{
            fontSize: "1em",
            fontWeight: "700",
            marginTop: "5vh",
            color: "#616161",
          }}
        >
          與我們聯絡。
        </span>
      </div>
    </div>
  );
};

const ProtectedJoinRoute = ({ component: Component, ...rest }) => {
  return (
    <Route
      {...rest}
      render={(props) => {
        const queryString = window.location.search;
        const urlParams = new URLSearchParams(queryString);
        const hasInviteParams =
          urlParams.get("confirmationHash") && urlParams.get("email");

        if (hasInviteParams) {
          return <Join {...props} />;
        }
        return <Redirect to="/login" />;
      }}
    />
  );
};

const App = () => {
  const { language, userEmail } = document.getElementById("root").dataset;

  React.useEffect(() => {
    changeLanguage(language);
  }, []);

  return (
    <Router>
      <Switch>
        <Route path="/login">
          {userEmail ? <Redirect to="/" /> : <LoginPage />}
        </Route>
        <Route path="/signup">
          {userEmail ? <Redirect to="/" /> : <RegistrationPage />}
        </Route>
        <Route path="/forgot">
          {userEmail ? <Redirect to="/" /> : <ForgetPasswordPage />}
        </Route>
        <Route path="/reset_password">
          {userEmail ? <Redirect to="/" /> : <ResetPasswordPage />}
        </Route>
        <Route path={["/confirmation", "/joinGroup"]}>
          <Activation />
        </Route>
        <Route path="/">
          <Redirect
            to={{
              pathname: userEmail ? "/confirmation" : "/login",
              search: window.location.search,
            }}
          />
        </Route>
      </Switch>
    </Router>
  );
};

root.render(<App />);
