import i18n from "i18next";
import { initReactI18next } from "react-i18next";

const loadTranslations = async (language) => {
  const response = await fetch(`/api/translations/${language}`);
  const translations = await response.json();
  return translations;
};

i18n
  .use(initReactI18next)
  .init({
    resources: {},
    lng: "zh", // Set the initial language
    interpolation: {
      escapeValue: false,
    },
    react: {
      useSuspense: false,
    },
  });

export const changeLanguage = async (language) => {
  let callLanguage = language;
  if(callLanguage==""||callLanguage==null||callLanguage=="null"){
    callLanguage = "zh";
  }
  const translations = await loadTranslations(callLanguage);
  i18n.addResourceBundle(callLanguage, "translation", translations);
  i18n.changeLanguage(callLanguage);
};

export default i18n;