import React from 'react'
import {
  useLocation
} from 'react-router-dom'
import axios from 'axios'
import Nav from './components/common/Nav';

const ResetPasswordPage = (props) => {
  const { search } = useLocation()
  const params = new URLSearchParams(search)
  const [state, setState] = React.useState({
    token: params.get('resetToken') || "",
    email: params.get('email') || "",
    password: "",
    passwordConfirm: ""
  })
  const [finished,setFinished]=React.useState(false)

  const handleChange = (e) => {
    setState({...state, [e.target.name]: e.target.value})
  }

  const handleSubmit = (e) => {
    e.preventDefault()
    let passed=true
    let message = document.getElementById("message")
    

    if (state.email == null ||state.email == "") {
      passed=false
      message.innerText = "帳號不可為空白";
      setTimeout(() => (message.innerText = ""), 5000);
      return;
    }
    if (state.token == null ||state.token == "") {
      passed=false
      message.innerText = "驗證碼不可為空白";
      setTimeout(() => (message.innerText = ""), 5000);
      return;
    }
    if (state.password == null ||state.password == "") {
      passed=false
      message.innerText = "密碼不可為空白";
      setTimeout(() => (message.innerText = ""), 5000);
      return;
    }
    if (state.password !== state.passwordConfirm) {
      passed=false
      message.innerText = "密碼與確認密碼不相符";
      setTimeout(() => (message.innerText = ""), 5000);
      return;
    }
    if(passed){
      axios.put('/api/reset_password', {
        token: state.token,
        email: state.email,
        password: state.password
      })
      .then(res => {
        if (res.data.successful) {
          setFinished(true)
        } else {
          message.innerText = res.data.message
          setTimeout(() => message.innerText = "", 5000)
        }
      })
      .catch(err => {
        message.innerText = "發生錯誤，請稍後再試"
        setTimeout(() => message.innerText = "", 5000)
      })
    }
    
  }

  return (
    <div style={{
      height: "100%"}}>
        <Nav/>
      <div
        className="w3-container w3-light-grey"
        style={{
          display:!finished?"block":"none",
          height: "100%",
          padding: "100px 16px",
          fontFamily: "noto sans tc,Arial,sans-serif",
          fontWeight:"600"
        }}
        id="contact"
      >
        <p className="w3-center w3-large"></p>
        <div style={{ marginTop: "48px" }}>
          <div className="w3-col l12">
            <div className="w3-col l4">&nbsp;</div>
            <div className="w3-col l4">
              <h3
                className="w3-center"
                style={{ fontWeight: "700", fontSize: "24px" }}
              >
                重新設定密碼
              </h3>
              <p >
                *帳號
                <input
                  style={{ borderRadius: "10px" }}
                  className="w3-input w3-border m-radius"
                  type="email"
                  placeholder="有效Email電子信箱，如：example@abc.com"
                  required
                  name="email"
                  value={state.email}
                  onChange={handleChange}
                />
              </p>
              <p >
              *驗證碼
                <input
                  style={{ borderRadius: "10px" }}
                  className="w3-input w3-border m-radius"
                  placeholder="請至以上信箱收取驗證碼"
                  required
                  name="token"
                  value={state.token}
                  onChange={handleChange}
                />
              </p>
              <p >
              *設定新密碼
                <input
                  style={{ borderRadius: "10px" }}
                  className="w3-input w3-border m-radius"
                  type="password"
                  placeholder="六位數以上密碼"
                  required
                  name="password"
                  value={state.password}
                  onChange={handleChange}
                />
              </p>
              <p >
              *驗證新密碼
                <input
                  style={{ borderRadius: "10px" }}
                  className="w3-input w3-border m-radius"
                  type="password"
                  placeholder="與上方同樣之密碼"
                  required
                  name="passwordConfirm"
                  value={state.passwordConfirm}
                  onChange={handleChange}
                />
              
              </p>
              <p
                id="message"
                style={{ color: "#DB485B", minHeight: "0px" }}
              ></p>
                            <p
                id="message2"
                style={{ color: "#288EEC", minHeight: "0px" }}
              ></p>
                <button
                  className="w3-button w3-black"
                  type="submit"
                  style={{ width: "100%" }}
                  id="button"
                  onClick={(e) => handleSubmit(e)}
                >
                  <i className="fa-solid fa-angle-right"></i> 送出
                </button>
              <h4
                className="w3-center"
                style={{
                  marginTop: "100px",
                  fontSize: "20px",
                  fontWeight: "700",
                }}
              >
                <a className="w3-center-a" href="/">
                  {" "}
                  ← 回到登入頁面
                </a>
              </h4>
            </div>
            <div className="w3-col l4"></div>
          </div>
        </div>
      </div>
      <div
        className="w3-container w3-light-grey"
        style={{
          display:finished?"block":"none",
          height: "100%",
          padding: "100px 16px",
          fontFamily: "noto sans tc,Arial,sans-serif",
          fontWeight:"600"
        }}
        id="contact"
      >
        <p className="w3-center w3-large"></p>
        <div style={{ marginTop: "48px" }}>
          <div className="w3-col l12">
            <div className="w3-col l4">&nbsp;</div>
            <div className="w3-col l4">
              <h3
                className="w3-center"
                style={{ fontWeight: "700", fontSize: "24px" }}
              >
                密碼重新設定成功！
              </h3>
              <div style={{ margin: "20px 3vw" }}>
                請點選下方按鈕即可以新密碼登入Playreal。
                <br />
                <br />
                <button
                  className="w3-button w3-black"
                  style={{ width: "100%" }}
                  id="button"
                  onClick={() =>  window.location.replace("/")}
                >
                 直接登入
                </button>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  )
}

export default ResetPasswordPage
