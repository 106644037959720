import React from "react";
import { Link, useLocation } from "react-router-dom";
import axios from "axios";
import _, { debounce } from "lodash";
import Nav from "./components/common/Nav";
const RegistrationPage = (props) => {
  const { search } = useLocation();
  const [state, setState] = React.useState({
    name: "",
    email: "",
    password: "",
    passwordConfirm: "",
    bday: "",
    sex: "",
  });
  const [finished, setFinished] = React.useState(false);
  const [tempToken, setTempToken] = React.useState("");

  const handleChange = (e) => {
    setState({ ...state, [e.target.name]: e.target.value });
  };

  const sendConfirmMail = () => {
    if (finished == false) return;
    axios
      .post("/api/account_confirm", {
        email: state.email,
      })
      .then((res) => {
        if (res.data["successful"] !== false) {
          let message = document.getElementById("message2");
          message.innerText = "驗證信已寄出，請至信箱收信";
          setTimeout(() => (message.innerText = ""), 5000);
        } else {
          //alert(res.data.message);
        }
      })
      .catch((err) => {
        console.log(err);
      });
  };

  const skip = () => {
    if (finished == false) return;
    axios
      .post("/api/users/sign_in/temp", {
        token: tempToken,
      })
      .then((res) => {
        if (res.data["successful"] == true) {
          setTimeout(() => {
            let test =
              window.location.href.includes("localhost") ||
              window.location.href.includes("98.14")
                ? true
                : false;
            if (test) {
              window.location.replace(window.location.origin + "/" + search);
            } else {
              let targetUrl;
              if (window.location.hostname.startsWith("build")) {
                targetUrl = "https://new.playreal.city/";
              } else if (window.location.hostname.startsWith("uat")) {
                targetUrl = "https://uatgo.playreal.city/";
              } else {
                // Default to new.playreal.city if no specific environment is matched
                targetUrl = "https://new.playreal.city/";
              }
              window.location.replace(targetUrl + search);
            }
          }, 500);
        } else {
          let message = document.getElementById("message2");
          message.innerText = "已超過自動登入時效，請回到登入頁登入";
          setTimeout(() => (message.innerText = ""), 5000);
        }
      })
      .catch((err) => {
        console.log(err);
      });
  };

  const handleSubmit = (e) => {
    e.preventDefault();
    let message = document.getElementById("message");
    let check = document.getElementById("contract");
    let passed = true;

    if (state.email == null || state.email == "") {
      passed = false;
      message.innerText = "帳號不可為空白";
      setTimeout(() => (message.innerText = ""), 5000);
      return;
    }
    if (state.password == null || state.password == "") {
      passed = false;
      message.innerText = "密碼不可為空白";
      setTimeout(() => (message.innerText = ""), 5000);
      return;
    }
    if (state.password !== state.passwordConfirm) {
      passed = false;
      message.innerText = "密碼與確認密碼不相符";
      setTimeout(() => (message.innerText = ""), 5000);
      return;
    }
    if (state.name == null || state.name == "") {
      passed = false;
      message.innerText = "暱稱不可為空白";
      setTimeout(() => (message.innerText = ""), 5000);
      return;
    }
    if (!check.checked) {
      passed = false;
      message.innerText = "未勾選使用者條款";
      setTimeout(() => (message.innerText = ""), 5000);
      return;
    }
    if (passed) {
      axios
        .post("/api/users/account", {
          name: state.name,
          email: state.email,
          password: state.password,
          sex: state.sex,
          bday: state.bday,
        })
        .then((res) => {
          if (res.data.successful) {
            setFinished(true);
            setTempToken(res.data.tempToken);
          } else {
            message.innerText = res.data.message;
            setTimeout(() => (message.innerText = ""), 5000);
          }
        })
        .catch((err) => {
          message.innerText = "發生錯誤，請稍後再試";
          setTimeout(() => (message.innerText = ""), 5000);
        });
    }
  };

  return (
    <div style={{ height: "100%", fontWeight: "600" }}>
      <Nav />
      <div
        className="w3-container w3-light-grey"
        style={{
          display: !finished ? "block" : "none",
          height: "100%",
          padding: "100px 16px",
          fontFamily: "noto sans tc,Arial,sans-serif",
          overflowY: "auto",
        }}
        id="contact"
      >
        <p className="w3-center w3-large"></p>
        <div style={{ marginTop: "48px" }}>
          <div className="w3-col l12">
            <div className="w3-col l4">&nbsp;</div>
            <div className="w3-col l4">
              <h3
                className="w3-center"
                style={{ fontWeight: "700", fontSize: "24px" }}
              >
                註冊 Playreal ID
              </h3>
              <p style={{ margin: "20px 3vw" }}>
                *設定帳號
                <input
                  style={{ borderRadius: "10px" }}
                  className="w3-input w3-border m-radius"
                  type="email"
                  placeholder="有效Email電子信箱，如：example@abc.com"
                  required
                  name="email"
                  value={state.email}
                  onChange={handleChange}
                />
              </p>
              <p style={{ margin: "20px 3vw" }}>
                *設定密碼
                <input
                  style={{ borderRadius: "10px" }}
                  className="w3-input w3-border m-radius"
                  type="password"
                  placeholder="六位數以上密碼"
                  required
                  name="password"
                  value={state.password}
                  onChange={handleChange}
                />
              </p>
              <p style={{ margin: "20px 3vw" }}>
                *驗證密碼
                <input
                  style={{ borderRadius: "10px" }}
                  className="w3-input w3-border m-radius"
                  type="password"
                  placeholder="與上方同樣之密碼"
                  required
                  name="passwordConfirm"
                  value={state.passwordConfirm}
                  onChange={handleChange}
                />
              </p>
              <p style={{ margin: "20px 3vw" }}>
                *使用者名稱
                <input
                  style={{ borderRadius: "10px" }}
                  className="w3-input w3-border m-radius"
                  type="text"
                  placeholder=""
                  required
                  name="name"
                  value={state.name}
                  onChange={handleChange}
                />
              </p>
              {1 == 1 ? null : (
                <>
                  <p style={{ margin: "20px 3vw" }}>
                    出生年月（統計研究與遊戲分級用途）
                    <input
                      className="w3-input w3-border m-radius"
                      type="month"
                      placeholder=""
                      required
                      name="bday"
                      onChange={handleChange}
                    />
                  </p>
                  <p style={{ margin: "20px 3vw" }}>
                    性別（統計研究用途）
                    <br />
                    <label
                      style={{
                        marginRight: "20px",
                        display: "inline-flex",
                        alignItems: "center",
                        width: "60px",
                      }}
                    >
                      <input
                        type="radio"
                        name="sex"
                        value="woman"
                        onChange={handleChange}
                        style={{ marginRight: "10px" }}
                      />
                      女性
                    </label>
                    <label
                      style={{
                        marginRight: "20px",
                        display: "inline-flex",
                        alignItems: "center",
                        width: "60px",
                      }}
                    >
                      <input
                        type="radio"
                        name="sex"
                        value="man"
                        onChange={handleChange}
                        style={{ marginRight: "10px" }}
                      />
                      男性
                    </label>
                    <label
                      style={{
                        marginRight: "20px",
                        display: "inline-flex",
                        alignItems: "center",
                        width: "60px",
                      }}
                    >
                      <input
                        type="radio"
                        name="sex"
                        value="other"
                        onChange={handleChange}
                        style={{ marginRight: "10px" }}
                      />
                      其他
                    </label>
                  </p>
                </>
              )}

              <div
                style={{
                  display: "flex",
                  alignItems: "flex-start",
                  justifyContent: "center",
                  flexDirection: "column",
                  padding: "0 3vw",
                }}
              >
                <div style={{ display: "flex", alignItems: "center" }}>
                  <input
                    type="checkbox"
                    style={{ marginRight: "10px" }}
                    id="contract"
                    name="contract"
                  />
                  我已閱讀並同意
                </div>

                <div>
                  <a
                    href="https://playreal.com.tw/law.html"
                    className="m-list"
                    target="_blank"
                  >
                    Playreal 使用者條款
                  </a>{" "}
                  及{" "}
                  <a
                    href="https://playreal.com.tw/law2.html"
                    className="m-list"
                    target="_blank"
                  >
                    Playreal Go 使用者條款
                  </a>
                </div>
              </div>
              <p
                id="message"
                style={{
                  color: "#DB485B",
                  minHeight: "0px",
                  padding: "20px 3vw",
                }}
              ></p>
              <p style={{ margin: "20px 3vw" }}>
                <button
                  className="w3-button w3-black"
                  type="submit"
                  style={{ width: "100%" }}
                  id="button"
                  onClick={(e) => handleSubmit(e)}
                >
                  <i className="fa-solid fa-angle-right"></i> 註冊
                </button>
              </p>
              <div style={{ display: "flex", justifyContent: "center" }}>
                {" "}
                <a href="/login" className="w3-center-a">
                  我已經有帳號了
                </a>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div
        className="w3-container w3-light-grey"
        style={{
          display: finished ? "block" : "none",
          height: "100%",
          padding: "100px 16px",
          fontFamily: "noto sans tc,Arial,sans-serif",
          overflowY: "auto",
        }}
        id="contact"
      >
        <p className="w3-center w3-large"></p>
        <div style={{ marginTop: "48px" }}>
          <div className="w3-col l12">
            <div className="w3-col l4">&nbsp;</div>
            <div className="w3-col l4">
              <h3
                className="w3-center"
                style={{ fontWeight: "700", fontSize: "24px" }}
              >
                已寄發驗證信
              </h3>
              <p style={{ margin: "20px 3vw" }}>
                請至您註冊的電子郵件信箱：{state.email}{" "}
                收取信件，並點結信件中的連結開通帳號。
                <br />
                <br />
                <p
                  id="message2"
                  style={{ color: "#288EEC", minHeight: "0px" }}
                ></p>
                <button
                  className="w3-button w3-black"
                  onClick={() => skip()}
                  style={{ width: "100%" }}
                >
                  略過驗證，直接登入遊戲
                </button>
                <button
                  className="w3-button"
                  type="submit"
                  style={{ width: "100%", border: "solid 2px" }}
                  id="button"
                  onClick={(e) => sendConfirmMail(e)}
                >
                  <i className="fa-solid fa-angle-right"></i> 重寄驗證信
                </button>
              </p>
              <div style={{ display: "flex", justifyContent: "center" }}>
                <a href="/" className="w3-center-a">
                  回到登入頁面
                </a>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default RegistrationPage;
